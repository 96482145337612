
































































































import Vue from "vue";
import { Article } from "@/core/models";
import { Action, Getter } from "vuex-class";
import { Component, Watch, Prop } from "vue-property-decorator";

@Component
export default class ArticleActions extends Vue {
  @Prop({ default: () => {} }) article!: Article;

  // Edit/Delete
  @Getter("news/editArticleLoading") editArticleLoading!: boolean;
  @Getter("news/editedArticleID") editedArticleID!: number;
  @Getter("news/deleteArticleLoading") deleteArticleLoading!: boolean;
  @Getter("news/deletedArticleID") deletedArticleID!: number;
  @Action("news/editArticle") editArticleAction!: Function;
  @Action("news/deleteArticle")
  deleteArticleAction!: (i: number) => Promise<void>;

  editDialog = false;
  newTitle = "";
  newBody = "";
  textRules = [
    (v: string) => {
      if (!v) return "Field cannot be empty.";
      else if (v.trim().length == 0) return "Field cannot be empty.";
      else return true;
    },
  ];
  get currentlyBeingEdited() {
    return this.editArticleLoading && this.editedArticleID == this.article.ID;
  }
  editArticle() {
    let data = {
      articleId: this.article.ID,
      newTitle: this.newTitle,
      newBody: this.newBody,
    };
    this.editArticleAction(data);
  }
  @Watch("currentlyBeingEdited")
  handleEdit(value: boolean) {
    if (!value) this.editDialog = false;
  }
  @Watch("editDialog")
  toggleEdit() {
    if (this.editDialog) {
      this.newTitle = this.article.Title;
      this.newBody = this.article.Body;
    } else {
      this.newTitle = "";
      this.newBody = "";
    }
  }

  deleteDialog = false;
  get currentlyBeingDeleted() {
    return (
      this.deleteArticleLoading && this.deletedArticleID == this.article.ID
    );
  }
  async deleteArticle() {
    await this.deleteArticleAction(this.article.ID);
    this.$router.replace("/news");
  }

  beforeDestroy() {
    if (this.currentlyBeingDeleted) this.$router.push("/news");
  }
}
